<!-- 多处使用，因此不参杂业务（请求接口） -->
<template>
  <div class="duration-pick-bar">
    <rectangle-radio-bar
      :dataArray="dayArray"
      :selectedItem="selectedTime.day"
      @onSelected="onDaySelected"
    ></rectangle-radio-bar>
    <el-date-picker
      ref="datePicker"
      class="duration-pick-bar-date-picker"
      size="mini"
      v-model="selectedTime.duration"
      type="daterange"
      value-format="timestamp"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :default-time="['12:00:00', '12:00:00']"
      @change="onDurationSelected"
    ></el-date-picker>
  </div>
</template>

<script>
import RectangleRadioBar from "@c/common/rectangle-radio-bar.vue";
import { DatePicker } from "element-ui";
import { SelectedTimeModel } from "@c/common/duration-pick-bar/selected-time-model.js";
export default {
  components: {
    RectangleRadioBar,
    elDatePicker: DatePicker,
  },

  props: {
    inputSelectedTime: SelectedTimeModel,
  },

  data() {
    return {
      dayArray: SelectedTimeModel.dayArray,
      selectedTime: undefined,
    };
  },

  created() {
    this.selectedTime = this.inputSelectedTime;
  },

  watch: {
    inputSelectedTime(value) {
      this.selectedTime = value;
    },
  },

  methods: {
    onDaySelected(index) {
      this.selectedTime.day = this.dayArray[index];
      switch (this.selectedTime.day) {
        case "自定义":
          this.$refs.datePicker.focus();
          break;
        default:
          this.$emit("onDurationSelected", this.selectedTime);
          break;
      }
    },

    onDurationSelected() {
      this.selectedTime.day = "自定义";
      this.$emit("onDurationSelected", this.selectedTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.duration-pick-bar {
  display: flex;
  align-items: center;

  &-date-picker {
    margin-left: 20px;
  }

  /deep/.el-range-editor--mini .el-range__icon {
    display: none;
  }

  /deep/.el-range-editor--mini .el-range-input {
    color: #595959;
    font-size: 14px;
  }

  /deep/.el-range-editor--mini .el-range-separator {
    color: #595959;
    font-size: 14px;
    height: 20px;
  }

  /deep/.el-range-editor--mini .el-range__close-icon {
    display: none;
  }

  /deep/.el-date-editor--daterange.el-input__inner {
    justify-content: center;
    // flex-grow: 1;
    border: 1px solid rgba($color: #000000, $alpha: 0.15);
    height: 32px;
    width: 272px;
  }
}
</style>