<template>
  <button
    class="pick-button"
    :class="[isActive ? 'active' : 'normal']"
    @click="onClick"
  >
    <p>{{ value }}</p>
    <img :src="arrowIcon" />
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    arrowIcon: String,
    value: String,
    isActive: Boolean,
  },

  methods: {
    onClick: function () {
      this.$emit("onClick", this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-button {
  color: #262626;
  font-size: 14px;
  width: 240px;
  height: 32px;
  background-color: white;
  border-radius:4px;
  box-sizing: border-box;
  padding-right: 20px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 12px;
  }
}

.active {
  border: solid 1px #1989fa;
}

.normal {
  border: solid 1px rgba($color: #000000, $alpha: 0.15);
}
</style>