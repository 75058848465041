class SelectedTimeModel {

    static dayArray = ["近7天", "近1月", "近3月", "自定义"]

    constructor(day, duration) {
        this.day = day;
        this.duration = duration
    }

    getTime() {
        switch (this.day) {
            case "近7天":
                return 7
            case "近1月":
                return 30
            case "近3月":
                return 90
            case "自定义":
                return `${this.duration[0]}-${this.duration[1]}`;
            default:
                break;
        }
    }
}

export { SelectedTimeModel }