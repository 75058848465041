<template>
  <el-date-picker class="single-report-selector-date-picker" v-model="singleReportCreateDate"
    :picker-options="pickerOptions" :default-value="defalutDate" :clearable="false"
    @change="onDaySelected"></el-date-picker>
</template>

<script>
import { DatePicker } from "element-ui";
import { DateTool } from "@js/date-tool.js";
import { filterEmpty } from "@js/empty-tool";
export default {
  components: {
    elDatePicker: DatePicker,
  },

  props: {
    uid: Number,
    hospitalId: String,
    inputSingleReportCreateDate: Date,
  },

  data() {
    return {
      singleReportCreateDate: undefined,
      bloodOxygenReportDateArray: [],
      breathReportDateArray: [],
      defalutDate: undefined,
      pickerOptions: {
        cellClassName: (time) => {
          const dateStr = DateTool.milliTimestampToStr(time.getTime(), "YYYY-MM-DD");
          const isIncludeBloodOxygen =
            this.bloodOxygenReportDateArray.includes(dateStr);
          const isIncludeBreath = this.breathReportDateArray.includes(dateStr);
          if (isIncludeBloodOxygen && !isIncludeBreath) {
            return "blood-oxygen-date";
          }
          if (!isIncludeBloodOxygen && isIncludeBreath) {
            return "breath-date";
          }
          if (isIncludeBloodOxygen && isIncludeBreath) {
            return "blood-oxygen-date breath-date";
          }
        },
      },
    };
  },

  watch: {
    inputSingleReportCreateDate(value) {
      this.singleReportCreateDate = value;
    },

    async uid() {
      this.bloodOxygenReportDateArray = await this.getBloodOxygenReportDateList();
      this.breathReportDateArray = await this.getBreathReportDateList();
      const bloodOxygenLatestDate = this.bloodOxygenReportDateArray[0]
      const breathLatestDate = this.breathReportDateArray[this.breathReportDateArray.length - 1]
      if (bloodOxygenLatestDate && breathLatestDate) {
        if (bloodOxygenLatestDate > breathLatestDate) {
          this.defalutDate = new Date(bloodOxygenLatestDate)
        } else {
          this.defalutDate = new Date(breathLatestDate)
        }
      } else if (bloodOxygenLatestDate) {
        this.defalutDate = new Date(bloodOxygenLatestDate)
      } else if (breathLatestDate) {
        this.defalutDate = new Date(breathLatestDate)
      }

    },
  },

  methods: {
    async onDaySelected(value) {
      this.$emit("onDaySelected", value);
    },

    async getBloodOxygenReportDateList() {
      try {
        const params = {
          patientId: this.uid,
        };
        return await this.$api.getBloodOxygenReportDateList(params);
      } catch (error) {
        return []
      }
    },

    async getBreathReportDateList() {
      try {
        const params = filterEmpty({
          uid: this.uid,
          hospitalId: this.hospitalId,
        });
        return await this.$api.getReportDateList(params);
      } catch (error) {
        return [];
      }
    },
  },
};
</script>

<style lang="scss">
.blood-oxygen-date>div ::after {
  content: "";
  position: absolute;
  right: 1px;
  top: 2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff6d4a;
}

.breath-date>div ::before {
  content: "";
  position: absolute;
  left: 1px;
  top: 2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #4da0fb;
}

.el-date-table td.current:not(.disabled) span {
  color: #606266;
  background-color: #edf5ff;
  border-radius: 5px;
}
</style>