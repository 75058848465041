<template>
  <div class="list-picker">
    <pick-button
      class="list-picker-button"
      :arrowIcon="arrowIcon"
      :value="selectedInfo"
      :isActive="isShowList"
      @onClick="onPickButtonClick"
    ></pick-button>
    <pick-list-list-picker
      class="list-picker-list"
      :isShowList="isShowList"
      :itemArray="infoArray"
      :initialItem="selectedInfo"
      @onSelected="onSelectedIndexChanged"
      @onMaskClick="onMaskClick"
    ></pick-list-list-picker>
  </div>
</template>

<script>
import PickButton from "@c/common/list-picker/pick-button.vue";
import PickListListPicker from "@c/common/pick-list/pick-list-list-picker.vue";

export default {
  components: {
    PickButton,
    PickListListPicker,
  },

  props: {
    inputInfoArray: Array,
    inputSelectedInfo: String,
    arrowIcon: {
      type: String,
      default: require("@imgs/panel-alert-triangle.png"),
    },
  },

  data: function () {
    return {
      infoArray: [],
      selectedInfo: "",
      isShowList: false,
      currentIndex: null,
    };
  },

  created() {
    this.reloadData()
  },

  watch: {
    inputInfoArray() {
      this.reloadData()
    },

    inputSelectedInfo() {
      this.reloadData()
    },
  },

  methods: {
    onPickButtonClick() {
      this.isShowList = !this.isShowList;
    },

    onSelectedIndexChanged(index) {
      this.isShowList = false;
      //空list处理
      if (!this.inputInfoArray.length) {
        return; //相当于写死
      }
      this.$emit("onSelectedIndexChanged", index);
    },

    onMaskClick() {
      this.isShowList = false;
    },

    reloadData() {
      this.infoArray = [...this.inputInfoArray];
      this.selectedInfo = this.inputSelectedInfo;
      //空list处理
      if (!this.inputInfoArray.length) {
        this.infoArray.unshift("暂无数据");
        this.selectedInfo = "暂无数据";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-picker {
  width: 100%;
  position: relative;

  &-button {
    width: 100%;
    height: 100%;
  }
}
</style>