<template>
  <div class="rectangle-radio-bar">
    <button
      class="rectangle-radio-bar-item"
      :class="selectedItem == item ? 'rectangle-radio-bar-item-selected' : ''"
      v-for="(item, index) of dataArray"
      :key="index"
      @click="onSelected(index)"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    selectedItem: String,
    dataArray: Array,
  },

  methods: {
    onSelected(index) {
      this.$emit("onSelected", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.rectangle-radio-bar {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: flex;
  margin: 5px 0px;

  &-item {
    color: #595959;
    background-color: white;
    border-right: 1px solid #d9d9d9;
    width: 50%;
    min-width: 60px;
    height: 32px;

    &-selected {
      color: white;
      background-color: #1890fb;
    }
  }

  :last-child {
    border-right: none !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

</style>