<template>
  <div class="report-type-select-bar">
    <div class="report-type-select-bar-container">
      <div class="report-type-select-bar-container-left">
        <rectangle-radio-bar class="report-type-select-bar-container-left-edition-selector" :dataArray="editionArray"
          :selectedItem="selectedEdition" @onSelected="onEditionSelected"></rectangle-radio-bar>
        <rectangle-radio-bar class="report-type-select-bar-container-left-day-type-selector" :dataArray="dayTypeArray"
          :selectedItem="selectedDayType" @onSelected="onDayTypeSelected"></rectangle-radio-bar>
      </div>
      <div class="report-type-select-bar-container-middle">
        <single-report-selector v-show="selectedDayType == '单天'" :uid="uid" :reportTypeArray="reportTypeArray"
          :inputSelectedReportType="inputSelectedReportType" :inputSingleBreathReportId="inputSingleBreathReportId"
          :inputSingleOxygenReportId="inputSingleOxygenReportId" :hospitalId="reportTypeInitialInfo.hospitalId"
          @onSingleReortSelected="onSingleReortSelected" ref="singleReportSelecor">
        </single-report-selector>
        <duration-pick-bar v-if="selectedDayType == '多天'" :inputSelectedTime="inputSelectedTime"
          @onDurationSelected="onDurationSelected"></duration-pick-bar>
      </div>
      <div class="report-type-select-bar-container-right">
        <button v-show="selectedDayType == '单天' && selectedReportType == '血氧报告'"
          class="report-type-select-bar-container-right-button" @click="onShareClick">
          分享
        </button>
        <button class="report-type-select-bar-container-right-button" @click="onPrintClick">
          打印报告
        </button>
        <button class="report-type-select-bar-container-right-button" @click="onDownloadClick">
          下载PDF
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RectangleRadioBar from "@c/common/rectangle-radio-bar.vue";
import DurationPickBar from "@c/common/duration-pick-bar/duration-pick-bar.vue";
import SingleReportSelector from "@c/report/report-type-select-bar/single-report-selector/single-report-selector.vue";
import { SelectedTimeModel } from "@c/common/duration-pick-bar/selected-time-model.js";
import { DatePicker } from "element-ui";

export default {
  components: {
    RectangleRadioBar,
    DurationPickBar,
    SingleReportSelector,
    elDatePicker: DatePicker,
  },

  props: {
    reportTypeInitialInfo: Object,
  },

  data() {
    return {
      uid: undefined,
      hospitalId: undefined,
      //edition
      editionArray: ["医生版", "患者版"],
      selectedEdition: "医生版",
      //dayType
      dayTypeArray: ["单天", "多天"],
      selectedDayType: undefined,
      //reportType
      reportTypeArray: ["呼吸机报告", "血氧报告"],
      inputSelectedReportType: "呼吸机报告",
      selectedReportType: undefined,
      //singleReport
      inputSingleBreathReportId: undefined,
      singleBreathReportId: undefined,
      inputSingleOxygenReportId: undefined,
      singleBloodOxygenReportId: undefined,
      //reportDuration
      inputSelectedTime: undefined,
      selectedTime: undefined,
      reportDuration: undefined,
    };
  },

  async mounted() {
    this.uid = this.reportTypeInitialInfo.uid;
    this.hospitalId = this.reportTypeInitialInfo.hospitalId;
    this.inputSelectedReportType = this.reportTypeArray[this.reportTypeInitialInfo.reportType - 1]
    this.singleBloodOxygenReportId = this.reportTypeInitialInfo.singleBloodOxygenReportId ? Number(this.reportTypeInitialInfo.singleBloodOxygenReportId) : undefined
    //获取初始设置和数据
    const singleBreathReportId = this.reportTypeInitialInfo.singleReportID;
    if (this.inputSelectedReportType === '呼吸机报告') {
      this.selectedDayType = singleBreathReportId ? "单天" : "多天";
    } else {
      this.selectedDayType = "单天";
    }
    switch (this.selectedDayType) {
      case "单天":
        this.inputSelectedTime = new SelectedTimeModel("近3月", undefined);
        this.inputSingleBreathReportId = singleBreathReportId;
        this.inputSingleOxygenReportId = this.singleBloodOxygenReportId
        break;
      case "多天":
        //要将JSON字符串转化为SelectedTimeModel模型类
        const inputSelectedTime = this.reportTypeInitialInfo.inputSelectedTime;
        this.inputSelectedTime = new SelectedTimeModel(
          inputSelectedTime.statisticsDay,
          inputSelectedTime.statisticsDuration
        );
        this.inputSingleBreathReportId = await this.getSingleBreathReportID(
          this.inputSelectedTime.getTime()
        );
        break;
      default:
        break;
    }
    //多日报告
    this.selectedTime = this.inputSelectedTime;
    this.reportDuration = await this.getReportDuration(
      this.selectedTime.getTime()
    );
    this.switchReport()
  },

  methods: {
    onEditionSelected(index) {
      this.selectedEdition = this.editionArray[index];
      this.switchReport();
    },

    onDayTypeSelected(index) {
      this.selectedDayType = this.dayTypeArray[index];
      this.switchReport();
    },

    onSingleReortSelected(value) {
      this.selectedReportType = value.selectedReportType;
      this.singleBreathReportId = value.singleBreathReportId;
      this.singleBloodOxygenReportId = value.singleBloodOxygenReportId;
      this.switchReport();
    },

    async onDurationSelected(value) {
      this.selectedTime = value;
      this.reportDuration = await this.getReportDuration(
        this.selectedTime.getTime()
      );
      this.switchReport();
    },

    async getReportDuration(time) {
      try {
        let days, start, end
        if (isNaN(time)) {
          let duration = time.split("-")
          start = duration[0]
          end = duration[1]
        } else {
          days = time
        }
        const params = {
          hospitalId: this.hospitalId,
          uid: this.uid,
          time: days,
          start: start,
          end: end
        };
        const data = await this.$api.getReportDuration(params);
        this.inputSelectedTime.duration = [data.start * 1000, data.end * 1000];
        return [data.start, data.end];
      } catch {
        this.errMsg = "暂无报告";
      }
    },

    async getSingleBreathReportID(time) {
      try {
        let days, start, end
        if (isNaN(time)) {
          let duration = time.split("-")
          start = duration[0]
          end = duration[1]
        } else {
          days = time
        }
        const params = {
          hospitalId: this.hospitalId,
          uid: this.uid,
          time: days,
          start: start,
          end: end
        };
        const data = await this.$api.getReportDuration(params);
        if (data.reportId) {
          return data.reportId;
        } else {
          throw "无法获取报告单日报告ID"
        }
      } catch {
        this.errMsg = "无法获取报告单日报告ID";
      }
    },

    switchReport() {
      this.$emit("onSwitchReport", {
        selectedEdition: this.selectedEdition,
        selectedDayType: this.selectedDayType,
        selectedReportType: this.selectedReportType,
        singleBreathReportId: this.singleBreathReportId,
        singleBloodOxygenReportId: this.singleBloodOxygenReportId,
        reportDuration: this.reportDuration,
      });
    },

    onPrintClick() {
      this.$emit("onPrint");
    },

    onDownloadClick() {
      this.$emit("onDownload");
    },

    onShareClick() {
      this.$emit("onShare");
    }
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .report-type-select-bar {
    display: none !important;
  }
}

.report-type-select {
  background-color: white;
  font-size: 40px;

  &-bar {
    @include z-index-max;
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-container {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 0px 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      &-left {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // flex-grow: 1;

        &-edition-selector {
          margin-right: 42px;
        }

        &-day-type-selector {
          margin-right: 20px;
        }
      }

      &-middle {
        display: flex;
        flex: 1;
      }

      &-right {
        // width: 296px;
        display: flex;
        justify-content: space-between;

        &-button {
          color: white;
          background-color: #6cc291;
          border-radius: 4px;
          width: 88px;
          height: 32px;
          margin: 5px 0px;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>